import React, { useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Checkbox, Paper, Button
} from '@mui/material';

const sampleData = [
  { id: 1, processNumber: '1030507-11.2017.8.26.0016', client: 'John Doe', title: 'Title 1', stage: 'Initial', date: '01/01/2021' },
  { id: 2, processNumber: '1030507-11.2017.8.26.0017', client: 'Jane Smith', title: 'Title 2', stage: 'Initial', date: '02/01/2021' },
  // Add more rows as needed
];

const TableComponent = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const isSelected = (id) => selectedRows.includes(id);

  const handleSelectAllClick = () => {
    if (selectedRows.length === sampleData.length) {
      setSelectedRows([]);
    } else {
      const newSelectedRows = sampleData.map((n) => n.id);
      setSelectedRows(newSelectedRows);
    }
  };

  const handleRowClick = (id) => {
    const selectedIndex = selectedRows.indexOf(id);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = newSelectedRows.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }

    setSelectedRows(newSelectedRows);
  };

  return (
    <div>
      <Paper>
        <Button onClick={handleSelectAllClick}>
          {selectedRows.length === sampleData.length ? 'Deselect All' : 'Select All'}
        </Button>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={selectedRows.length > 0 && selectedRows.length < sampleData.length}
                    checked={sampleData.length > 0 && selectedRows.length === sampleData.length}
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
                <TableCell>Process Number</TableCell>
                <TableCell>Client</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Stage</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sampleData.map((row) => (
                <TableRow
                  key={row.id}
                  onClick={() => handleRowClick(row.id)}
                  role="checkbox"
                  aria-checked={isSelected(row.id)}
                  selected={isSelected(row.id)}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isSelected(row.id)}
                    />
                  </TableCell>
                  <TableCell>{row.processNumber}</TableCell>
                  <TableCell>{row.client}</TableCell>
                  <TableCell>{row.title}</TableCell>
                  <TableCell>{row.stage}</TableCell>
                  <TableCell>{row.date}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      
    </div>
  );
};

export default TableComponent;




