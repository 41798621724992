import React, { useState, useContext,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import UserContext from "../context/UserContext";
import "./Login.css"; // Make sure this imports your CSS

const checkCredentials = async (username, password) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/Atria/check_credentials_okta`,
      {
        params: {
          username,
          password,
        },
      }
    );

    if (response.status === 200 && response.data.status) {
      const data = response.data.data;

      const firstName = data._embedded.user.profile.firstName;

      const lastName = data._embedded.user.profile.lastName;

      const emailID = data._embedded.user.profile.login;

      console.log(firstName, lastName, ".....");
      return data;
    }
    return false;
  } catch (error) {
    console.error("Error in checkCredentials:", error);
    throw new Error("Unable to check credentials. Please try again.");
  }
};

const verifyFactor = async (stateToken, passCode, factorId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/Atria/check_mfa_okta`,
      {
        params: {
          state_token: stateToken,
          pass_code: passCode,
          factor_id: factorId,
        },
      }
    );

    if (response.status === 200 && response.data) {
      return true;
    } else {
      console.error(`Verification failed: ${response.data}`);
      return true;
    }
  } catch (error) {
    console.error("Error in verifyFactor:", error);
    throw new Error("MFA verification failed. Please try again.");
  }
};

const Login = ({ setIsAuthenticated }) => {
  
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [mfaCode, setMfaCode] = useState("");
  const [error, setError] = useState("");
  const [mfaRequired, setMfaRequired] = useState(false);
  const [stateToken, setStateToken] = useState("");
  const [factorId, setFactorId] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setSessionID, setFirstName, setLastName, setEmail } =
    useContext(UserContext);
    useEffect(() => {
      if(localStorage.getItem('isAuthenticated') === 'true'){
      navigate('/pha') ;}
      // Set loading to false after checking authentication
    }, [setIsAuthenticated]);
  
//     console.log("sotrage login app",localStorage)
//   console.log("login",localStorage.getItem("isAuthenticated"))

  //const [sessionId, setSessionId] = useState(null);
  // const [sessionID, setSessionID] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      // Check for admin credentials
      if (username === "test@gmail.com" && password === "test") {
        setFirstName("Ajeeth");
        setLastName("goML");
        setEmail("ajeethkumar.n@goml.io");
        //
        // Generate session ID for admin login
        const sessionResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/Atria/sessionID`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (sessionResponse.ok) {
          const sessionData = await sessionResponse.json();
          setSessionID(sessionData); // Store session ID in state
          setIsAuthenticated(true);
          setUsername({ username });
          navigate("/pha");
        } else {
          setError("Failed to generate session ID for admin login");
        }
        return;
      }

      const result = await checkCredentials(username, password);
      if (result) {
        setUsername({ username });
        const firstName = result._embedded.user.profile.firstName;
        const lastName = result._embedded.user.profile.lastName;
        const emailID = result._embedded.user.profile.login;

        setFirstName(firstName);
        setLastName(lastName);
        setEmail(emailID);

        if (result.status === "MFA_REQUIRED") {
          const factor = result._embedded.factors.find(
            (f) =>
              f.provider === "OKTA" && f.factorType === "token:software:totp"
          );
          if (factor) {
            setStateToken(result.stateToken);
            setFactorId(factor.id);
            setMfaRequired(true);
          } else {
            setError("MFA factor not found");
          }
        } else if (result.status === "SUCCESS") {
          const sessionResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/Atria/sessionID`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          if (sessionResponse.ok) {
            const sessionData = await sessionResponse.json();
            setSessionID(sessionData); // Store session ID in state
            setIsAuthenticated(true);
            navigate("/pha");
          } else {
            setError("Failed to generate session ID");
          }
        } else {
          setError("Login failed");
        }
      } else {
        setError("Username/password is incorrect. Please try again.");
      }
    } catch (error) {
      setError("An unexpected error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleMfaSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    try {
      const login = await verifyFactor(stateToken, mfaCode, factorId);
      if (login) {
        setIsAuthenticated(true);
        // Call the API to generate session ID after successful MFA verification
        const sessionResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/Atria/sessionID`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              // Pass any necessary headers here
            },
          }
        );

        if (sessionResponse.ok) {
          const sessionData = await sessionResponse.json();

          setSessionID(sessionData); // Store session ID in state
          setIsAuthenticated(true);
          setUsername({ username });

          console.log("session id🥶", sessionData);

          navigate("/pha");
        } else {
          setError("Failed to generate session ID for admin login");
        }
        navigate("/pha"); // Redirect after successful MFA verification
      } else {
        setError(
          "Invalid code / Timeout - retry once again with the new code 😇"
        );
      }
    } catch (error) {
      setError("MFA verification failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  //======

  return (
    <div className="login-container">
      <div className="login-box">
        <img className="logo" src="./logo.png" alt="Logo" />
        <h2 className="Login-heading">Login using your OKTA account</h2>

        {error && <div className="error">{error}</div>}
        {!mfaRequired ? (
          <form className="loginForm" onSubmit={handleLogin}>
            <label className="label">Username</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
            <label className="label">Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button type="submit" disabled={loading}>
              {loading ? "Logging in..." : "Login"}
            </button>
          </form>
        ) : (
          <form onSubmit={handleMfaSubmit}>
            <input
              type="text"
              value={mfaCode}
              onChange={(e) => setMfaCode(e.target.value)}
              placeholder="Enter MFA Code"
              required
            />
            <button type="submit" disabled={loading}>
              {loading ? "Verifying..." : "Verify MFA"}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Login;
