// import React, { useEffect, useContext } from "react";
// import { useOktaAuth } from "@okta/okta-react";
// import UserContext from "../context/UserContext";

// const OktaLogin = () => {
//   const { oktaAuth, authState } = useOktaAuth();
//   const { setUser, setFirstName, setLastName, setEmail } = useContext(UserContext);

//   useEffect(() => {
//     if (!authState) return;

//     if (!authState.isAuthenticated) {
//       oktaAuth.signInWithRedirect();
//     } else {
//       const userInfo = authState.idToken.claims;
//       setUser({ username: userInfo.email });
//       setFirstName(userInfo.given_name);
//       setLastName(userInfo.family_name);
//       setEmail(userInfo.email);
//     }
//   }, [authState, oktaAuth, setUser, setFirstName, setLastName, setEmail]);

//   if (!authState || !authState.isAuthenticated) {
//     return <div>Loading...</div>;
//   }

//   return <div>Welcome to the app!</div>;
// };

// export default OktaLogin;


import React, { useEffect, useContext } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useNavigate } from "react-router-dom";
import UserContext from "../context/UserContext";

// const OktaLogin = () => {
//   const { oktaAuth, authState } = useOktaAuth();
//   const { setSessionID, setFirstName, setLastName, setEmail } = useContext(UserContext);
//   const navigate = useNavigate();

//   useEffect(() => {
//     if (!authState) return;

//     if (!authState.isAuthenticated) {
//       oktaAuth.signInWithRedirect();
//     } else {
//       const userInfo = authState.idToken.claims;
//       // setUser({ username: userInfo.email });
//       setFirstName(userInfo.given_name || '');  // Update first name
//       setLastName(userInfo.family_name || '');  // Update last name
//       setEmail(userInfo.email || '');  // Update email
//       const sessionResponse = fetch(
//         `${process.env.REACT_APP_API_URL}/Atria/sessionID`,
//         {
//           method: "GET",
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       );
// //




// ///
     


//       if (sessionResponse.ok) {
//         const sessionData = sessionResponse.json();
//         console.log("session-")
//         console.log("😅",sessionData,"😅");
//         setSessionID(sessionData); // Store session ID in state
//       } else {
//         setSessionID("_");
//         console.log("Failed to generate session ID for admin login");
//       }

  

//       // Redirect user after login
//       navigate("/pha");  // Change '/pha' to the path you want to redirect after login
//     }
//   }, [authState, oktaAuth, setFirstName, setLastName, setEmail,setSessionID, navigate]);

//   if (!authState) {
//     return <div>Loading...</div>;
//   }

//   return <div>Welcome to the Atria AI</div>;
// };

// export default OktaLogin;



const OktaLogin = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const { setSessionID, setFirstName, setLastName, setEmail,sessionID } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSessionID = async () => {
      if (!authState) return;

      if (!authState.isAuthenticated) {
        oktaAuth.signInWithRedirect();
      } else {
        const userInfo = authState.idToken.claims;
        setFirstName(userInfo.name || '-');  // Update first name
        setLastName(userInfo.preferred_username || '-');  // Update last name
        setEmail(userInfo.email || '');  // Update email
        console.log(sessionID,"🦖🦖🦖")
        
        try {
          const sessionResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/Atria/sessionID`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          if (sessionResponse.ok) {
            const sessionData = await sessionResponse.json();
            console.log("😅", sessionData,userInfo, "😅");
            setSessionID(sessionData); // Store session ID in state
          } else {
            setSessionID("_");
            console.log("Failed to generate session ID for admin login");
          }
        } catch (error) {
          setSessionID("_");
          console.log("Error fetching session ID:", error);
        }

        // Redirect user after login
        console.log(sessionID,"🦖🦖🦖🦖")
        navigate("/pha");  // Change '/pha' to the path you want to redirect after login
      }
    };

    fetchSessionID();
  }, [authState, oktaAuth, setFirstName, setLastName, setEmail, setSessionID, navigate]);

  if (!authState) {
    return <div>Loading...</div>;
  }

  return <div>Welcome to the Atria AI</div>;
};

export default OktaLogin;

