// import React, { useContext, useEffect,useState } from "react";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import Login from "./components/Login";
// import NewPage from "./components/Newpage";
// import MDCC from "./components/MDCC";
// import ProtectedRoute from "./ProtectedRoute";
// import UserContext from './context/UserContext';
// import TableComponent from "./components/table";

// const App = () => {
//   const { isAuthenticated, setIsAuthenticated } = useContext(UserContext);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     console.log("sotrage login app",localStorage)
//     const storedIsAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
//     setIsAuthenticated(storedIsAuthenticated);
//     setLoading(false); // Set loading to false after checking authentication
//   }, [setIsAuthenticated]);

//   if (loading) {
//     return <div>Loading...</div>; // Show loading indicator while checking authentication status
//   }

//   return (
//     <Router>
//       <Routes>
//         <Route path="/" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
//         <Route 
//           path="/pha" 
//           element={
//             <ProtectedRoute isAuthenticated={isAuthenticated}>
//               <NewPage />
//             </ProtectedRoute>
//           } 
//         />
//         <Route 
//           path="/mdcc" 
//           element={
//             <ProtectedRoute isAuthenticated={isAuthenticated}>
//               <MDCC />
//             </ProtectedRoute>
//           } 
//         />
//         <Route path="/table" element={<TableComponent />} />
//       </Routes>
//     </Router>
//   );
// };

// export default App;






import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Security, LoginCallback, SecureRoute } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import Login from "./components/Login";
import NewPage from "./components/Newpage";
import MDCC from "./components/MDCC";
import TableComponent from "./components/table";
import { UserProvider } from './context/UserContext';
import config from './oktaConfig';
import OktaLogin from './components/OktaLogin';
import ProtectedRoute from './ProtectedRoute';

const oktaAuth = new OktaAuth(config.oidc);

// Add restoreOriginalUri function here
const restoreOriginalUri = async (_oktaAuth, originalUri) => {
  window.location.replace(toRelativeUrl(originalUri || '/', window.location.origin));
};

const App = () => {
  return (
    <UserProvider>
      <Router>
        {/* Pass restoreOriginalUri to Security */}
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <Routes>
            <Route path="/" element={<OktaLogin />} />
            <Route path="/login/callback" element={<LoginCallback />} />
            <Route path="/pha" element={
              <ProtectedRoute>
                <NewPage />
              </ProtectedRoute>
            }/>
            <Route path="/mdcc" element={
              <ProtectedRoute>
                <MDCC />
              </ProtectedRoute>
            }/>
            <Route path="/table" element={<TableComponent />}/>
          </Routes>
        </Security>
      </Router>
    </UserProvider>
  );
};

export default App;
