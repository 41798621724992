

import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import './Sidebar.css';
import { useOktaAuth } from '@okta/okta-react';

const Sidebar = ({
  submitted,
  reportsFetched,
  handleDownload,
  handlegooglelink,
  loadingButton,
  completeGeneration
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const location = useLocation();
  const navigate = useNavigate(); // Move useNavigate here at the top level
  const { oktaAuth } = useOktaAuth();


  useEffect(() => {
    if (completeGeneration || reportsFetched) {
      setIsExpanded(true);
    }
  }, [completeGeneration, reportsFetched]);

  const handleLogout = () => {
    // Perform logout with Okta and clear local storage
    oktaAuth.signOut()
      .then(() => {
        console.log("Logged out successfully");
        localStorage.clear(); // Clear all user data
        navigate('/'); // Redirect to login page
      })
      .catch((err) => {
        console.error("Error during logout:", err);
      });
  };

  //   const handleLogout = () => {
  //   console.log("1")
  //   localStorage.clear();
  //   navigate('/');
  //   console.log("2")
  // localStorage.clear();

// };
  const handleToggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };

  const getActiveClass = (path) => {
    return location.pathname === path ? 'active-background' : '';
  };

  return (
    <div className={`sidebar ${isExpanded ? 'expanded' : ''}`}>
      <div className="collapse" onClick={handleToggleSidebar}>
        <img className={`icons arrow-icon ${isExpanded ? 'rotated' : ''}`} src="/right.png" alt="Toggle Sidebar" />
      </div>

      <div className={`sidebar-icon ${getActiveClass('/pha')}`}>
        <Link to="/pha" className={location.pathname === '/pha' ? 'active' : ''}>
          <img className="icons" src="/pha.png" alt="PHA Icon" />
        </Link>
        {isExpanded && (
          <ul className="sidebar-links">
            <li>
              <Link to="/pha" className={location.pathname === '/pha' ? 'active' : ''}>
                PHA
              </Link>
            </li>
          </ul>
        )}
      </div>

      <div className={`sidebar-icon ${getActiveClass('/mdcc')}`}>
        <Link to="/mdcc" className={location.pathname === '/mdcc' ? 'active' : ''}>
          <img className="icons" src="/mdcc.png" alt="MDCC Icon" />
        </Link>
        {isExpanded && (
          <>
            <ul className="sidebar-links">
              <li>
                <Link to="/mdcc" className={location.pathname === '/mdcc' ? 'active' : ''}>
                  MDCC
                </Link>
              </li>
            </ul>
          </>
        )}
      </div>

      {/* Conditionally render Google Docs and Download icons */}
      {completeGeneration && (
        <>
          <div className="sidebar-text" onClick={handlegooglelink}>
            <Link className="sidebar-links-text google-link" disabled={loadingButton === "google"}>
              {loadingButton === "google" ? "Loading..." : "Open in Google Docs"}
            </Link>
          </div>
          <div className="sidebar-text" onClick={handleDownload}>
            <Link className="sidebar-links-text download-btn" disabled={loadingButton === "downloadOriginal"}>
              {loadingButton === "download" ? "Loading..." : "Download"}
            </Link>
          </div>
        </>
      )}

      {reportsFetched && (
        <>
          <div className="sidebar-text" onClick={handlegooglelink}>
            <Link className="sidebar-links-text google-link" disabled={loadingButton === "googleOriginal"}>
              {loadingButton === "googleOriginal" ? "Loading..." : "Open in Google Docs"}
            </Link>
          </div>
          <div className="sidebar-text" onClick={handleDownload}>
            <Link className="sidebar-links-text download-btn" disabled={loadingButton === "downloadOriginal"}>
              {loadingButton === "downloadOriginal" ? "Loading..." : "Download Docs"}
            </Link>
          </div>
        </>
      )}

      <br />
      <hr style={{ width: "80%", height: "3px", border: "none", backgroundColor: "#405a51" }} />

      <div className="sidebar-icon" onClick={handleLogout}>
        <img className="icons" src="/logout.png" alt="Logout Icon" />
        {isExpanded && (
          <ul className="sidebar-links" style={{ paddingBottom: "14px" }}>
            <li>Logout</li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
