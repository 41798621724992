import React, { createContext, useState, useEffect } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [sessionID, setSessionID] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const storedSessionID = localStorage.getItem('sessionID');
    const storedFirstName = localStorage.getItem('firstName');
    const storedLastName = localStorage.getItem('lastName');
    const storedEmail = localStorage.getItem('email');
    const storedIsAuthenticated = localStorage.getItem('isAuthenticated') === 'true';

    if (storedSessionID) setSessionID(storedSessionID);
    if (storedFirstName) setFirstName(storedFirstName);
    if (storedLastName) setLastName(storedLastName);
    if (storedEmail) setEmail(storedEmail);
    setIsAuthenticated(storedIsAuthenticated);
  }, []);

  useEffect(() => {
    localStorage.setItem('sessionID', sessionID);
    localStorage.setItem('firstName', firstName);
    localStorage.setItem('lastName', lastName);
    localStorage.setItem('email', email);
    localStorage.setItem('isAuthenticated', isAuthenticated);
  }, [sessionID, firstName, lastName, email, isAuthenticated]);

  return (
    <UserContext.Provider value={{ sessionID, setSessionID, firstName, setFirstName, lastName, setLastName, email, setEmail, isAuthenticated, setIsAuthenticated }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
