

import React, { useState,useEffect, useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import axios from 'axios';
import UserContext from '../context/UserContext';
import '../Newpage.css';
import TableComponent from './TableComponent';
import Sidebar from '../Sidebar';
import { useLocation } from "react-router-dom";
const MDCC = () => {
  const [patientId, setPatientId] = useState('');
  const [physicianReport, setPhysicianReport] = useState('');
 
  const [tableData, setTableData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [gdocsLink, setGdocsLink] = useState(null);
  const [selectedRows, setSelectedRows] = useState({});
  const [loadingButton, setLoadingButton] = useState(null);
  const [submitData, setSubmitData] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [isGsheet, setisGsheet] = useState(false);
  const { user, sessionID ,firstName,lastName,email} = useContext(UserContext);
  const [noDataTables, setNoDataTables] = useState({});
  const [reportGenerated, setReportGenerated] = useState(false);
  const[completeGeneration,setCompleteGeneration]=useState(false);
  const controller = new AbortController();
  const signal = controller.signal;
  
  setTimeout(() => controller.abort(), 720000);

  const location = useLocation();

  const username = user && user.username ? user.username.split('@')[0] : 'Guest';

  const tables_names = ["doctor_notes", "image_scan", "visits", "vitals", "demographics", "lab", "medication"];
  // const tables_names = ["doctor_notes", "image_scan", "visits", "vitals", "demographics", "normal","abnormal", "medication"];
  
  const toCamelCase = (str) => {
    return str
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };




  const useSidebarState = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  
    const handleSidebarToggle = (isOpen) => {
      setIsSidebarOpen(isOpen);
    };
  
    return { isSidebarOpen, handleSidebarToggle };
  };
  




  const [scrolledToTop, setScrolledToTop] = useState(true);
  
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setScrolledToTop(true);
      } else {
        setScrolledToTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

const handleMoveToTop = () => {
  if (scrolledToTop) {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  } else {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
};

  

  

  const fetchTableData = async (patientId, signal) => {
    try {
      setTableData({});
      setDataFetched(false);
      const noDataTablesTemp = {};
      let tableDataTemp = {};
  



      for (const table_name of tables_names) {
        const url = `${process.env.REACT_APP_API_URL}/Atria/mdcc_tables?patient_id=${patientId}&table_name=${table_name}`;
        try {
          const response = await axios.get(url, { signal });
  
          if (response.data === null || response.data.length === 0) {
            console.log(`Table "${table_name}" has no data.`);
  
            noDataTablesTemp[table_name] = true;
            // Explicitly set null for tables with no data
            tableDataTemp[table_name] = null;
  
            setNoDataTables(prevNoDataTables => ({
              ...prevNoDataTables,
              ...noDataTablesTemp,
            }));
  
            continue; // Skip to the next table
          }
  
          if (table_name === "lab") {
            const flattenedData1 = response.data[1];
            const flattenedData2 = response.data[0];
  
            let dataToSet = {};
  
            if (flattenedData1 !== null && flattenedData2 !== null) {
              const dataWithIds_normal = flattenedData1.map((row, index) => ({
                id: index + 1, ...row,
              }));
              dataToSet["lab_normal_report"] = dataWithIds_normal;
  
              const dataWithIds_abnormal = flattenedData2.map((row, index) => ({
                id: index + 1, ...row,
              }));
              dataToSet["lab_abnormal_report"] = dataWithIds_abnormal;
            } else {
              const availableData = flattenedData1 || flattenedData2;
              if (availableData !== null) {
                const dataWithIds = availableData.map((row, index) => ({
                  id: index + 1, ...row,
                }));
                dataToSet["lab_report"] = dataWithIds;
              }
            }
  
            if (Object.keys(dataToSet).length > 0) {
              tableDataTemp = {
                ...tableDataTemp,
                ...dataToSet,
              };
            }
          } else {
            const dataWithIds = response.data.map((row, index) => ({
              id: index + 1, ...row,
            }));
  
            tableDataTemp = {
              ...tableDataTemp,
              [table_name]: dataWithIds,
            };
          }
  
        } catch (err) {
          console.log(`Error fetching data for table "${table_name}":`, err);
        }
      }
  
      // Finally set all table data (including those with null)
      setTableData(prevTableData => ({
        ...prevTableData,
        ...tableDataTemp,
      }));
      setDataFetched(true);
  
    } catch (err) {
      console.log('Error fetching table data:', err);
    }
  };
  
  

  console.log("🥶",noDataTables,"🥶");




const handleDownload = async () => {
  setLoadingButton('download');
  try {
    let combinedContent = `${physicianReport}\\n\n${apiResponse}`;

    const timestamp = new Date().toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    }).replace(/(\d{2})\/(\d{2})\/(\d{4}), (\d{2}):(\d{2}):(\d{2})/, '$4:$5:$6_$1-$2-$3');
    
    console.log(timestamp);
    
    
    console.log(timestamp);
    
    
      // //con.log(content, "🚩🚩🚩🚩");
      const fileName = `${patientId} MDCC (${timestamp})`;

      console.log("🐶",fileName);

      const inputData={first_name:firstName,last_name:lastName,email:email,sessionID:sessionID,title:fileName,patient_id:patientId,content:combinedContent}

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/Atria/MDCC_download`, 
        inputData
    , {
        responseType: 'arraybuffer',
        signal
    });
    

      if (response.status === 200) {
        const timestamp = new Date().toLocaleString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false
        }).replace(/(\d{2})\/(\d{2})\/(\d{4}), (\d{2}):(\d{2}):(\d{2})/, '$4:$5:$6_$1-$2-$3');
        const fileName = `${patientId} MDCC (${timestamp})`;
          const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
          const downloadUrl = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = downloadUrl;
          a.download = `${fileName}.docx`;
          a.click();
          window.URL.revokeObjectURL(downloadUrl);
          a.remove();

          //con.log(`File saved as report_${patientId}.docx`);
      } else {
          //con.error('Error downloading document: Server returned non-200 status');
      }
  } catch (error) {
      //con.error('Error downloading document:', error);
      // You can print out the error response here for more insight
      if (error.response) {
          const errorMessage = new TextDecoder().decode(error.response.data);
          console.log(errorMessage)
          //con.error('Server error message:', errorMessage);
      }
  } finally {
      setLoadingButton(null);
  }
};



  const handlegooglelink = async () => {
    setLoadingButton('google');
    setError(null); // Reset any previous error

    try {
        console.log("😎😎", apiResponse);
        let link = gdocsLink;
        
        // Combine physicianReport and apiResponse into the content string
        let combinedContent = `${physicianReport}\\n\n${apiResponse}`;
        console.log("🫥🫥", combinedContent, "🫥🫥");

        // Generate the timestamp for the file name
        const timestamp = new Date().toLocaleString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false
        }).replace(/(\d{2})\/(\d{2})\/(\d{4}), (\d{2}):(\d{2}):(\d{2})/, '$4:$5:$6_$1-$2-$3');
        
        // Create the file name using the patient ID and timestamp
        const fileName = `${patientId} MDCC (${timestamp})`;

        // Prepare the input data for the API request
        const inputData = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            sessionID: sessionID,
            title: fileName,
            patient_id: patientId,
            content: combinedContent
        };

        // Make the API request to generate the Google Docs link
        const gdocsResponse = await axios.post(`${process.env.REACT_APP_API_URL}/Atria/MDCC/gdocs_link`, inputData,{signal});
        
        // Update the link with the response data
        link = gdocsResponse.data;
        setGdocsLink(link);

        // Open the generated link in a new window
        if (link) {
            window.open(link);
        } else {
            throw new Error('Failed to generate Google Docs link.');
        }
    } catch (error) {
        // Handle any errors during the API request
        setError('Error generating Google Docs link.');
    } finally {
        // Reset the loading state
        setLoadingButton(null);
    }
};



 



  const fetchReport = async (url, setReport, sectionName) => {
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();
        let result = '';

        while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            result += value;

            // Check if the result so far is a valid JSON object
            try {
                const parsedResult = JSON.parse(result);
                if (typeof parsedResult === 'object' && !Array.isArray(parsedResult)) {
                    setReport(`Sorry, unable to generate the ${sectionName} summary.`);
                    return;
                }
            } catch (e) {
                // Continue appending to result if not yet a complete JSON
            }

            setReport(result); // Stream content to the report state
        }
    } catch (err) {
        //con.error('Error fetching the report:', err);
        setReport(`Sorry, unable to generate the ${sectionName} summary.`);
    }
};

const handleFetchReports = async () => {
    setDataFetched(false);
    setPhysicianReport('');
    setTableData({});
    setLoading(true);
    setError('');
    setSelectedRows({});

    try {
      const check_patient_url = `${process.env.REACT_APP_API_URL}/Atria/check_patient_id?patient_id=${patientId}`;
      const check_patient_response = await fetch(check_patient_url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          first_name: firstName,
          last_name: lastName,
          patient_id: patientId, // assuming patientId is already defined
          email: email,
          service: "PHA",
          sessionId: sessionID,
        }),
        signal
      });

        const is_patient = await check_patient_response.json();

        if (is_patient === true) {
            const physicianUrl = `${process.env.REACT_APP_API_URL}/Atria/Mdcc/physician_reference?patient_id=${patientId}`;
            await fetchReport(physicianUrl, setPhysicianReport, 'Physician',signal);

            await fetchTableData(patientId);
        } else {
            setPhysicianReport(`No data found for patient ID: ${patientId}`);
        }
    } catch (err) {
        setError('Failed to fetch the Physician report summary or table data.');
    } finally {
        setLoading(false);
    }
};








  const handleSubmit = (e) => {
    e.preventDefault();
    
    setDataFetched(false);
    setSubmitData(null);
    setSubmitted(false);
    handleFetchReports();
  };

  const handleRowSelection = (tableName, selectedRows) => {
    try {
      // Uncomment and adjust this section if needed
      // if (tableName === 'lab') {
      //   setSelectedRows(prevSelectedRows => ({
      //     ...prevSelectedRows,
      //     ...selectedRows
      //   }));
      // } else {
        setReportGenerated(true); 
      setSelectedRows(prevSelectedRows => ({
        ...prevSelectedRows,
        [tableName]: selectedRows
      }));
      // }
    } catch (err) {
      console.error("Error updating row selection:", err); // Log the error
    }
  };
  


  const handleNewId = async () => {
    try {
      // Reset all state variables
      setPatientId("");
    setPhysicianReport("");
    setTableData({});
    setLoading(false);
    setError("");
    setGdocsLink(null);
    setSelectedRows({});
    setLoadingButton(null);
    setSubmitData(null);
    setSubmitted(false);
    setDataFetched(false);
    setApiResponse(null);
    setNoDataTables({});
    setReportGenerated(false);
    setGdocsLink(false);

  setCompleteGeneration(false);
    
    
    setisGsheet(false);

    } catch (err) {
      console.error("Error resetting state:", err); // Log the error
    }
  };
  
  




  const handleFinalSubmit = async () => {
    setApiResponse(null);  // Clear previous API response
    setLoading(true);  // Start loading
    setSubmitted(false);  // Reset submission state
    setSubmitData(null);  // Clear previous submission data
    
  
    
    // Initialize result object with session state
    const result = {
      session_id: sessionID,  // Include session ID
      doctor_notes: [],
      image_reports: [],
      lab_abnormal_report: [],
      lab_normal_report: [],
      lab_report: [],
      medication: [],
      appointment: [],
      vitals: [],
      demographics: [],
      visits: []
    };
  
    const tables_names_info = ["doctor_notes", "image_scan", "visits", "vitals", "lab_normal_report", "lab_abnormal_report", "medication", "lab_report"];
  
    let tablesSelected = false;
  
    for (const table of tables_names_info) {
      if (selectedRows[table] && selectedRows[table].length > 0) {
        tablesSelected = true;
        if (table === "lab_normal_report") {
          result["lab_normal_report"] = tableData[table].filter(row => selectedRows["lab_normal_report"].includes(row.id));
        } else if (table === "lab_abnormal_report") {
          result["lab_abnormal_report"] = tableData[table].filter(row => selectedRows["lab_abnormal_report"].includes(row.id));
        } else if (table === "lab_report") {
          result["lab_report"] = tableData[table].filter(row => selectedRows["lab_report"].includes(row.id));
        } else if (table === "image_scan") {
          result["image_reports"] = tableData[table].filter(row => selectedRows["image_scan"].includes(row.id));
          result["image_scan"] = tableData[table].filter(row => selectedRows["image_scan"].includes(row.id));
        } else if (table === "visits") {
          const visitsData = tableData[table].filter(row => selectedRows[table].includes(row.id));
          result["visits"] = visitsData;
          result["appointment"] = visitsData;
        } else {
          result[table] = tableData[table].filter(row => selectedRows[table].includes(row.id));
        }
      } else {
        result[table] = []; // Ensure result[table] is an empty array if no rows are selected
      }
    }
  
    // Set submitData without demographics for UI display
    const resultForUI = { ...result };
    delete resultForUI.demographics; 
    // Remove demographics for UI display
    setSubmitData(resultForUI);
    setSubmitted(true);
    setCompleteGeneration(false); // Set submitted to true to show the Google Docs button
  
  
  try {
    const { session_id, ...resultWithoutSessionId } = result;

    const response = await fetch(`${process.env.REACT_APP_API_URL}/Atria/MdccSummary`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(resultWithoutSessionId), 
      signal:signal// Send the full result including session ID and demographics
    });
  
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
  
    const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();
    let result_value = '';
  try
{   while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      
      // Append the new chunk to the accumulated result
      result_value += value;
      
      // Process the currently received chunks and update state
      const processedValue = result_value.replace(/```/g, '');
      setApiResponse(processedValue); // Update the API response incrementally
    }
    const finalValue=result_value.replace(/```/g, '');
    setCompleteGeneration(true);
    setApiResponse(finalValue);
    setisGsheet(true);
  }catch(err){
    console.error(err)
  }
  finally{
    setLoading(false);
  }
  
    // After all chunks are processed
    
   
  } catch (err) {
    console.error('Error during final submit:', err); // Log the error
    // setError('Error during final submit.');
  } finally {
    setLoading(false); // End loading
  }
  
  };  






  useEffect(() => {
    if (apiResponse) {
      const processedValue = apiResponse.replace(/```/g, '');
      setApiResponse(processedValue);
    }
    if (isGsheet){
      const timestamp = new Date().toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }).replace(/[/,:]/g, ':');
      const fileName = `${patientId} MDCC (${timestamp})`;
  
      const gsheetLogUrl = `${process.env.REACT_APP_API_URL}/Atria/gsheet_log`;
      const executive_summary = `\n\n## Factual Summary \n\n${apiResponse || ''}`;
      const gsheetLogData = {
        first_name: firstName,
        last_name: lastName,
        patient_id: patientId,
        email: email,
        service: "MDCC",
        sessionId: sessionID,
        title: fileName,
        physcian_summary: physicianReport,
        executive_summary:executive_summary,
      };
  
      console.log("Sending data:", gsheetLogData);
      const sendGsheetLog = async () => {
        try {
          await axios.post(gsheetLogUrl, gsheetLogData,{
            headers: {
              'Content-Type': 'application/json'
            },
            signal: signal 
          });
          console.log("Data sent successfully");
        } catch (error) {
          console.error("Error sending data:", error);
          // You can handle the error here, like showing a user notification or retrying
        } finally {
          setisGsheet(false);
        }
      };
      sendGsheetLog();
    }
  }, [apiResponse,isGsheet]);
  
  







  const renderTable = (data) => (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            {Object.keys(data[0]).map(key => (
              <th key={key}>{key}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.id}>
              {Object.values(row).map((value, index) => (
                <td key={index}>{value}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );


  const { isSidebarOpen, handleSidebarToggle } = useSidebarState();
  console.log(tableData);
  return (
    <div className="newpage-background">
 {/* <Sidebar isOpen={isSidebarOpen} onToggle={handleSidebarToggle} /> */}
 <Sidebar
        isOpen={isSidebarOpen}
        onToggle={handleSidebarToggle}
        submitted={submitted}
        loadingButton={loadingButton}
        handleDownload={handleDownload}
        handlegooglelink={handlegooglelink}
        completeGeneration={completeGeneration}
      />
 <div className={`newpage-container ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
     
      <div className="newpage-box">
        <h2>MDCC</h2>
        {/* <h5>Hi {username}, Welcome to Atria MDCC. Enter your patient ID to get started.</h5> */}
        <h5>Hi {firstName}, Welcome to Atria MDCC. Enter your patient ID to get started.</h5>

        {error && <div className="error">{error}</div>}
        {
          !reportGenerated?(
        <form onSubmit={handleSubmit}>
          <input
            className="inbtnsel"
            value={patientId}
            onChange={(e) => setPatientId(e.target.value)}
            placeholder="Enter your patient ID"
            required
          />
          <button  className="getReport inbtnsel" type="submit" disabled={loading}>
            {loading ? 'Generating Report...' : 'Get Report'}
          </button>
        </form>
        ):(
          <button className="start-new-id inbtnsel" onClick={handleNewId}>
            Start with New ID
          </button>
        )
        
        }

      </div>

      <div id="report-container" className="report-container">
        {physicianReport && (
          <div id="executive-summary" className="report">
            <h3>Physician Reference</h3>
            <ReactMarkdown>{physicianReport}</ReactMarkdown>
          
           
          </div>
          
        )}
       
 {Object.keys(tableData).map((tableName) => (

          <TableComponent

            key={tableName}
            tableName={tableName}
            data={tableData[tableName]||[]}
            noData={noDataTables|| false}
            onSelectionChange={(selectedRows) => handleRowSelection(tableName, selectedRows)}
          />
        ))} 
{/* 
{tables_names.map((tableName) => (
  <TableComponent
    key={tableName}
    tableName={tableName}
    data={tableData[tableName] || []}
    noData={noDataTables[tableName] || false}
    onSelectionChange={(selectedRows) => handleRowSelection(tableName, selectedRows)}
  />
))} */}

        {dataFetched && (
          <>
          <button className="submitSelectedRows" onClick={handleFinalSubmit}>
            Submit Selected Rows
          </button>

          <button
      className={`moveToTop ${!scrolledToTop ? '' : 'rotated'}`}
      onClick={handleMoveToTop}
    >
      <img src="/upload.png" alt="Move to Top" />
    </button>

          </>
          
        )}

{submitted && (
          <div>
            {submitData && submitData.lab_normal_report && submitData.lab_normal_report.length > 0 && (
              <div className='no-table'>
                <h3 className='no-table'>Lab Normal Report:</h3>
                {renderTable(submitData.lab_normal_report)}
              </div>
            )}
            {submitData && submitData.lab_abnormal_report && submitData.lab_abnormal_report.length > 0 && (
              <div className='no-table'>
                <h3 className='no-table'>Lab Abormal Report:</h3>
                {renderTable(submitData.lab_abnormal_report)}
              </div>
            )}
            {submitData && submitData.lab_report && submitData.lab_report.length > 0 && (
              <div className='no-table'>
                <h3 className='no-table'>Lab Report:</h3>
                {renderTable(submitData.lab_report)}
              </div>
            )}

{
  tables_names.map((tableName, index) => {
    // Check if any rows are selected in any table
    const anyTableSelected = tables_names.some(
      (name) => name !== 'lab' && name !== 'demographics' && submitData && submitData[name] && submitData[name].length > 0
    );

    // If no table is selected, display "No table selected" and stop further rendering
    {/* if (!anyTableSelected) {
      return index === 0 ? (
        <div key="no-table-selected" className='no-table'>No table selected</div>
      ) : null;
    } */}

    // If a table is selected, render the rest as usual
    // return (
    //   tableName !== 'lab' && tableName !== 'demographics' && (
    //     <div key={tableName}>
    //       <h3 className='no-table'>{toCamelCase(tableName)}:</h3>
    //       {submitData && submitData[tableName] && (
    //         <>
    //           {submitData[tableName].length > 0 ? (
    //             renderTable(submitData[tableName])
    //           ) : (
    //             <div className='no-table'>Haven't selected any row from this particular table</div>
    //           )}
    //         </>
    //       )}
    //     </div>
    //   )
    // );

   return (
  tableName !== 'lab' && tableName !== 'demographics' && (
    <div key={tableName}>
      <h3 className='no-table'>{toCamelCase(tableName)}:</h3>
      {submitData && submitData[tableName] && (
        <>
          {submitData[tableName].length > 0 ? (
            renderTable(submitData[tableName])
          ) : (!tableData[tableName] || tableData[tableName].length === 0) ? (
            <div className='no-table'>No data available for this table</div>
          ) : (
            <div className='no-table'>Haven't selected any row from this particular table</div>
          )}
        </>
      )}
    </div>
  )
);

    
  })
}
{apiResponse && ( // Render the API response here
              <div className="api-response">
                <hr></hr>
                <ReactMarkdown>{apiResponse}</ReactMarkdown>
              </div>
            )}

         
            {error && <p style={{ color: 'rgb(77, 29, 29)', marginLeft: '20px' }}>{error}</p>}
          </div>
        )}
        {loading && <div className="loading">Generating...</div>}
        </div>
      </div>
    </div>
  );
};

export default MDCC;