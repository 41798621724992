


import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import '../Newpage.css';
import { styled } from '@mui/material/styles';

// Styled DataGrid with custom styles
const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-cell': {
    color: '#ded9ce', // Change text color of cells
  },

  '& .MuiDataGrid-columnHeaderTitle': {
    color: '#405a51', // Text color of column headers
    fontWeight: 'bold', // Make column header title bold
    fontSize: '16px', // Make column header title larger
  },
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: '#ded9ce', // Ensure the bottom border is visible
  },
  '& .MuiDataGrid-row.Mui-selected': {
    backgroundColor: 'rgba(0, 0, 0, 0.1)', // Dim selected rows
  },
  '& .MuiDataGrid-footerContainer': {
    display: 'none', // Footer color
  },
  '& .MuiCheckbox-root': {
    color: '#405a51', // Color of the checkbox
  },
  '& .MuiDataGrid-checkboxInput': {
    color: '#888', // Color of checkbox when selected
  },

}));


const TableComponent = ({ tableName, data, noData, onSelectionChange }) => {
  const [selectedRows, setSelectedRows] = useState([]);
  

  useEffect(() => {
    setSelectedRows([]);
  }, [data]);

  console.log("🤑",noData,tableName,"🤑");
  console.log("🐼",noData[tableName],"🐼");
  const obj = noData;
  if (tableName === 'demographics') {
    return null; // Skip rendering for demographics
  }

  // const firstKey = Object.keys(obj)[0];
  // if (obj[firstKey] === true) {
  //   return (
  //     <div>
  //       <h3 className='no-table'>{firstKey}:</h3>
  //       <div>No Data Availableee</div>
  //     </div>
  //   );
   
  // }

  const handleSelectionModelChange = (newSelectionModel) => {
    setSelectedRows(newSelectionModel);
    onSelectionChange(newSelectionModel);

    const selectedData = data.filter(row => newSelectionModel.includes(row.id));
    console.log(`Selected Rows for ${tableName}:`, selectedData);
  };

  const toCamelCase = (str) => {
    return str
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const renderTable = (rows, listName) => {
    try {
      if (rows.length > 0 && !rows.every(row => row.id)) {
        console.error('Some rows are missing the `id` property.');
        return (
          <div>
            <h4>{listName.replace('_', ' ')}</h4>
            <div>Error loading data. Check console for details.</div>
          </div>
        );
      }

      const columns = rows.length > 0
        ? Object.keys(rows[0])
            .filter(key => key !== 'id')
            .map(key => ({
              field: key,
              headerName: toCamelCase(key),
              width: 150,
              editable: false,
              cellClassName: 'vertical-align-top',
            }))
        : [];

      return (
        <div>
          <h4>{toCamelCase(listName)}</h4>
          {rows.length > 0 ? (
            <Box sx={{ height: 400, width: '100%' }}>
              <CustomDataGrid
                rows={rows}
                columns={columns}
                pagination
                hideFooterPagination
                hideFooterSelectedRowCount
                pageSize={100}
                rowsPerPageOptions={[100]}
                checkboxSelection
                disableSelectionOnClick
                onRowSelectionModelChange={handleSelectionModelChange}
                selectionModel={selectedRows}
                disableColumnMenu
                components={{
                  Toolbar: null,
                }}
                getRowId={(row) => row.id || row.someUniqueField}
              />
            </Box>
          ) : (
            <div className="tableMessage">No Data Available</div>
          )}
        </div>
      );
    } catch (error) {
      console.error(`Error rendering ${listName}:`, error);
      return (
        <div>
          <h4>{toCamelCase(listName)}</h4>
          <div>Error loading data. Check console for details.</div>
        </div>
      );
    }
  };

  // Handle lab report tables specifically
  if (tableName === 'lab') {
    const hasLabNormalReport = data.lab_normal_report && data.lab_normal_report.length > 0;
    const hasLabAbnormalReport = data.lab_abnormal_report && data.lab_abnormal_report.length > 0;
    const hasLabReport = data.lab_report && data.lab_report.length > 0;

    return (
      <div>
        {hasLabNormalReport && renderTable(data.lab_normal_report, 'lab normal report')}
        {hasLabAbnormalReport && renderTable(data.lab_abnormal_report, 'lab abnormal report')}
        {hasLabReport && renderTable(data.lab_report, 'lab report')}
        {!hasLabNormalReport && !hasLabAbnormalReport && !hasLabReport && (
          <div>No Data Available for Lab Reports</div>
        )}
      </div>
    );
  }

  // Handle all other tables


  return (
    <div>
      <h3 className='no-table'>{toCamelCase(tableName)}:</h3>
      {data && data.length > 0 ? (
        <Box sx={{ height: 300, width: '100%' }}>
          <CustomDataGrid
            rows={data}
            columns={Object.keys(data[0])
              .filter(key => key !== 'id')
              .map(key => ({
                field: key,
                headerName: toCamelCase(key),
                width: 400,
                editable: false,
              }))}
            pagination
            pageSize={100}
            rowsPerPageOptions={[100]}
            checkboxSelection
            disableSelectionOnClick
            onRowSelectionModelChange={handleSelectionModelChange}
            selectionModel={selectedRows}
            disableColumnMenu
            components={{
              Toolbar: null,
            }}
            getRowId={(row) => row.id || row.someUniqueField}
            sx={{
              '& .vertical-align-top': {
                verticalAlign: 'top', // Apply vertical alignment
              },
            }}
          />
        </Box>
      ) : (
        <div className="tableMessage">No information found for this patient in {tableName.replace('_', ' ')}</div>
      )}
    </div>
  );
};

export default TableComponent;