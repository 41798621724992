// import React from 'react';
// import { Navigate } from 'react-router-dom';

// const ProtectedRoute = ({ isAuthenticated, children }) => {
//   if (!isAuthenticated) {
//     return <Navigate to="/" />;
//   }

//   return children;
// };

// export default ProtectedRoute;

import React from 'react';
import { Navigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

const ProtectedRoute = ({ children }) => {
  const { authState } = useOktaAuth();

  if (!authState || !authState.isAuthenticated) {
    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectedRoute;
